<template>
	<v-col cols="12">
		<v-row class="houses-item-board-search">
			<v-col>
				<Select
					:value.sync="porch"
					:items="porchItems"
					item-text="name"
					item-value="_id"
					clearable
					placeholder="Выберите подъезд"
				/>
			</v-col>
			<v-col>
				<TextField
					:value.sync="fullname"
					:placeholder="placeholder"
					v-mask="'+7-###-###-##-##'"
					hide-details="auto"
					@focus="isFocused = !isFocused"
					@blur="isFocused = !isFocused"
				/>
			</v-col>
			<v-col>
				<TextField :value.sync="room" placeholder="Помещение" />
			</v-col>
			<v-col cols="2">
				<Button text @click.native="handleReset">Сбросить</Button>
			</v-col>
		</v-row>
	</v-col>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	name: "HousesItemBoardSearch",

	data() {
		return {
			isFocused: false,
			timer: {
				fullname: null,
				room: null,
			},
		};
	},

	computed: {
		...mapGetters({
			cEntrances: "board/GET_ENTRANCES",
		}),

		porchItems() {
			return this.cEntrances || [];
		},

		placeholder() {
			return this.isFocused ? "+7-" : "Телефон";
		},

		porch: {
			get() {
				return this.$store.getters["board/GET_SEARCH_PORCH"];
			},
			set(value) {
				this.$store.commit("board/SET_SEARCH", { field: "porch", value });
			},
		},
		fullname: {
			get() {
				return this.$store.getters["board/GET_SEARCH_FULLNAME"];
			},
			set(value) {
				this.$store.commit("board/SET_SEARCH", {
					field: "fullname",
					value,
				});
			},
		},
		room: {
			get() {
				return this.$store.getters["board/GET_SEARCH_ROOM"];
			},
			set(value) {
				this.timer.room = clearTimeout();
				this.timer.room = setTimeout(() => {
					this.$store.commit("board/SET_SEARCH", { field: "room", value });
				}, 500);
			},
		},
	},

	methods: {
		handleReset() {
			this.$store.commit("board/SET_SEARCH", null);
		},
	},
};
</script>

<style></style>
