<template>
	<Select :items="items" :value.sync="propValue" @change="handleChange" />
</template>

<script>
export default {
	name: "HousesItemBoardSelect",

	props: {
		value: {
			required: true,
		},
	},

	data() {
		return {
			propValue: null,
			items: [
				{ text: "Отсутствует", value: null },
				{ text: "Чердак", value: "1" },
				{ text: "Паркинг", value: "2" },
				{ text: "Тех. помещение", value: "3" },
			],
		};
	},

	watch: {
		value(e) {
			this.propValue = e;
		},
	},

	created() {
		this.propValue = this.value;
	},

	methods: {
		handleChange(e) {
			this.propValue = e;
			console.log(e);
			this.$emit("update:value", e);
		},
	},
};
</script>

<style lang="scss" scoped>
$grey: #f5f5f5;
.v-input {
	border: none !important;
	border-radius: 0 !important;
	text-align: center !important;
}
::v-deep .v-input__slot {
	background: $grey !important;
	text-align: center !important;
}
::v-deep .v-select__selection {
	width: 100%;
	justify-content: center;
}
</style>
