<template>
	<label class="image-select">
		<input type="file" accept="image/png" @change="handleChange" />
		<div v-if="!value" class="add-image d-flex flex-column align-center py-4">
			<v-icon class="mb-2" color="primary" x-large>
				mdi-camera-plus-outline
			</v-icon>
			<span class="font-weight-light subtitle-2">Добавить фото</span>
		</div>
		<v-img
			v-else-if="isUrl"
			width="100%"
			max-width="90%"
			max-height="100%"
			height="90%"
			cover
			:src="value"
		/>
		<v-progress-circular
			v-else
			color="primary"
			:size="20"
			indeterminate
		></v-progress-circular>
	</label>
</template>

<script>
export default {
	name: "ImageSelect",

	props: {
		value: {
			required: true,
		},
	},

	computed: {
		isUrl() {
			return !!this.value;
		},
	},

	methods: {
		handleChange(e) {
			const files = e.target.files;
			if (!files.length) return;
			this.$emit("update:value", files);
		},
	},
};
</script>

<style lang="scss" scoped>
input[type="file"] {
	display: none;
}
.image-select {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	background: #f5f5f5;
	margin-bottom: 20px;
	transition: background 0.3s ease;
	cursor: pointer;
	text-align: center;
	min-height: 100px;
	height: 100%;
	max-height: 150px;
	width: 100%;
	&:hover {
		background: #d5d5d5;
	}
}
</style>
