<template>
	<v-col class="houses-item-board-floors">
		<v-row>
			<v-col cols="12">
				<TextBox>
					{{ porchName }}
				</TextBox>
			</v-col>

			<v-col cols="12" class="d-flex align-center">
				<v-row>
					<v-col cols="1">
						<span class="text-subtitle-2 mr-6">Этажи</span>
					</v-col>
					<v-col cols="11">
						<HousesItemBoardSelect :value.sync="ground" />
					</v-col>
				</v-row>
			</v-col>

			<HousesItemBoardApartments />

			<v-col cols="12" class="d-flex align-center">
				<v-row>
					<v-col cols="1"></v-col>
					<v-col cols="11">
						<HousesItemBoardSelect :value.sync="ground" />
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</v-col>
</template>

<script>
import HousesItemBoardSelect from "@/components/HousesItemBoardSelect";
import HousesItemBoardApartments from "@/components/HousesItemBoardApartments";

export default {
	name: "HousesItemBoardFloors",

	components: {
		HousesItemBoardApartments,
		HousesItemBoardSelect,
	},

	data() {
		return {
			ground: null,
		};
	},

	computed: {
		porchName() {
			return (
				this.$store.getters["board/GET_SELECTED_PORCH"]?.name ||
				"Выберите подъезд для отображения"
			);
		},
	},
};
</script>

<style lang="scss" scoped></style>
