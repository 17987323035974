<template>
	<v-hover v-slot="{ hover }">
		<!-- min-width="280" -->

		<v-card
			width="100%"
			:elevation="hover ? 5 : 0"
			:class="{ 'on-hover': hover }"
			class="mb-3 transition-fast-in-fast-out"
			:active-class="'card-active'"
			:exact-active-class="'card-active'"
			:exact-path="'card-active'"
			outlined
			link
			:to="{
				path: `/houses/${item._id}`,
				query: $route.query,
			}"
			@click="$emit('change-page')"
		>
			<v-card-title
				class="subtitle-2 d-flex justify-space-between align-center pa-3"
			>
				<span>{{ item.apartment_address }}</span>
			</v-card-title>

			<v-card-text class="pa-3 pt-0 d-flex flex-column">
				<span class="caption font-weight-light">
					{{ item.name || "Название ЖК не указано" }}
				</span>
			</v-card-text>
		</v-card>
	</v-hover>
</template>

<script>
import { mapGetters } from "vuex";

import { statusColor } from "@/helpers/variables.js";

export default {
	name: "HousesItemListCard",
	props: {
		item: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			statusColor,
		};
	},
	computed: {
		...mapGetters({
			catalog: "catalog/GET_CATALOG",
		}),
		status() {
			const status = this.catalog.request_types.find(
				e => e.id === this.item.request_type_id
			);
			const color = this.statusColor[status.sys_name];
			return { ...status, color };
		},
	},
	created() {},
	methods: {
		deadline() {
			const today = new Date().setHours(0, 0, 0, 0);
			const deadline_date = new Date(
				this.item.employee_period_of_execution
			).setHours(0, 0, 0, 0);
			if (today === deadline_date) {
				return "warning";
			}
			if (today > deadline_date) {
				return "error";
			}
			return "";
		},
	},
};
</script>

<style lang="scss" scoped>
.v-card {
	width: 100% !important;
	&.card-active {
		border: thin solid #2eb85c !important;
		background: rgba(46, 184, 92, 0.09);
	}
}
</style>
