<template>
	<v-dialog v-model="dialog" persistent max-width="900px" scrollable>
		<v-card class="relative">
			<!-- Header -->
			<v-card-title
				class="d-flex justify-space-between border-bottom primary pt-2 pb-1"
			>
				<span class="subtitle-1 white--text">
					<span>Приемка дома</span>
				</span>
				<v-btn color="dark" @click="closeModal(false)" icon>
					<v-icon color="white">mdi-close</v-icon>
				</v-btn>
			</v-card-title>

			<!-- Progress bar -->
			<v-progress-linear
				v-if="loading"
				color="primary"
				height="5"
				indeterminate
			></v-progress-linear>

			<!-- Body MAP -->
			<v-card-text class="body pa-0">
        <v-card flat>
          <v-toolbar>
            <v-tabs
              v-model="tab"
              align-with-title
              fixed-tabs
              centered
            >

              <v-tab
                class="pa-0 ma-0 px-2 text-none text-body-2"
                v-for="(item, i) in tabs"
                :key="i"
              >
                {{ item }}
              </v-tab>
            </v-tabs>
          </v-toolbar>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-card-title>Инвентарный перечень: {{ apartment_address }}&nbsp;
                <v-btn v-show="items.inventories" color="primary" small icon @click="createPDF(tabs[0], 'inventory')">
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-data-table
                  v-if="items.inventories"
                  ref="inventory"
                  :headers="headers"
                  :items="items.inventories"
                  :items-per-page="30"
                  class="elevation-1" 
                  hide-default-footer
                  style="border: 1px solid lightgray;"
                >
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon
                      small
                      @click="deleteItem(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
                <v-btn :color="newFields ? 'secondary' : 'primary'" small block @click="newFields = !newFields"> {{ !newFields ? 'Добавить' : 'Скрыть' }}
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
                <v-row class="ma-2">
                  <template v-if="newFields">
                    <v-col cols="8">
                      <v-text-field v-model="params.name" outlined solo dense hide-details flat placeholder="Наименование">

                      </v-text-field>
                    </v-col>
                    <v-col cols="4" class="pl-0">
                      <v-text-field v-model="params.value" outlined solo dense hide-details flat placeholder="Измерение">

                      </v-text-field>
                    </v-col>
                    <v-btn block small color="primary" @click="addField">Создать</v-btn>
                  </template>
                </v-row>
              </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <v-card-title>Данные по благоустройству территории: {{ apartment_address }}&nbsp;
                <v-btn v-show="items.improvements  " color="primary" small icon @click="createPDF(tabs[1], 'improvement')">
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-data-table
                  v-if="items.improvements"
                  ref="improvement"
                  :headers="headers"
                  :items="items.improvements"
                  :items-per-page="30"
                  class="elevation-1" 
                  hide-default-footer
                  style="border: 1px solid lightgray;"
                >
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon
                      small
                      @click="deleteItem(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
                <v-btn :color="newFields ? 'secondary' : 'primary'" small block @click="newFields = !newFields"> {{ !newFields ? 'Добавить' : 'Скрыть' }}
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
                <v-row class="ma-2">
                  <template v-if="newFields">
                    <v-col cols="8">
                      <v-text-field v-model="params.name" outlined solo dense hide-details flat placeholder="Наименование">

                      </v-text-field>
                    </v-col>
                    <v-col cols="4" class="pl-0">
                      <v-text-field v-model="params.value" outlined solo dense hide-details flat placeholder="Измерение">

                      </v-text-field>
                    </v-col>
                    <v-btn block small color="primary" @click="addField">Создать</v-btn>
                  </template>
                </v-row>
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title>
                <h5>Вы уверены что хотите удалить данный элемент?</h5>
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogDelete = false">Отмена</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

import jsPDF from 'jspdf';
import 'jspdf-autotable';
import '../../public/assets/Helvetica Cyrillic-normal'
export default {
  props: ['house_id', 'apartment_address'],
	name: "HousesAcceptanceModal",
  data() {
    return {
      loading: false,
      newFields: false,
      dialogDelete: false,
      dialog: true,
      tab: null,
      tabs: [
        'Акт приемки МЖК "Инвентарный перечень"', 'Акт приемки МЖК "Данные по благоустройству"',
      ],
      data: {
        _method: "patch"
      },
      params: {
        name: '',
        value: '',
        amount: null
      },
      improvementsItems: [
        {name: 'Ограждения территории', value: 'пм', amount: ''},
        {name: 'Ограждения участков ', value: 'пм', amount: ''},
        {name: 'Калитки', value: 'шт', amount: ''},
        {name: 'Ворота', value: 'шт', amount: ''},
        {name: 'Урны', value: 'шт', amount: ''},
        {name: 'Контейнеры для мусоросборника', value: 'шт', amount: ''},
        {name: 'Парковые светильники', value: 'шт', amount: ''},
        {name: 'Скамейки в парковой зоне', value: 'шт', amount: ''},
        {name: 'МАЛЫЕ АРХИТЕКТУРНЫЕ ФОРМЫ', value: 'шт', amount: ''},
        {name: 'Деревья (с указанием гарантийного срока) ', value: 'шт', amount: ''},
        {name: 'Кустарники (с указанием гарантийного срока)', value: 'шт', amount: ''},
        {name: 'Газон (с указанием гарантийного срока)', value: 'м²', amount: ''},
        {name: 'Цветники (с указанием гарантийного срока)', value: 'шт', amount: ''},
        {name: 'Живая изгородь', value: 'м²', amount: ''},
        {name: 'Теннисная площадка', value: 'м²', amount: ''},
        {name: 'Баскетбольная площадка', value: 'м²', amount: ''},
        {name: 'Футбольная площадка', value: 'м²', amount: ''},
        {name: 'Велосипедная дорожка', value: 'м²', amount: ''},
        {name: 'Бордюры/асфальт/ тротуары/штакетники', value: 'м²', amount: ''},
        {name: 'Стенд с планом-схемой коттеджного городка с ночной подстветкой', value: 'шт', amount: ''},
        {name: 'Таблички с названием улицы, № переулка, дома и квартиры', value: 'шт', amount: ''},
        {name: 'Контейнеры для мусоросборника, согласованные с СЭС', value: 'шт', amount: ''},
        {name: 'Прочее', value: 'шт', amount: ''},
        {name: 'Ключи от почтовых ящиков', value: 'шт', amount: ''},
        {name: 'Инструкции по эксплуатации (Детские игровые комплексы, Уличное спортивное оборудование и пр.)', value: 'шт', amount: ''},
        {name: 'Сертификаты соответствия (Детские игровые комплексы, Уличное спортивное оборудование и пр.)', value: 'шт', amount: ''},
        
      ],
      inventoriesItems: [
        {name: 'Уличное освещение/опоры/светильники', value: 'Пм/шт', amount: ''},
        {name: 'Количество камер видеонаблюдения', value: 'шт', amount: ''},
        {name: 'Количество электрощитовых (ВРУ)', value: 'шт', amount: ''},
        {name: 'Количество счетчиков ОДН', value: 'шт', amount: ''},
        {name: 'Тепловые узлы', value: 'шт', amount: ''},
        {name: 'Приборы теплового  учета', value: 'шт', amount: ''},
        {name: 'Насосные станции', value: 'шт', amount: ''},
        {name: 'Автоматический полив (исполнительная документация)', value: 'Пм', amount: ''},
        {name: 'ТП', value: 'шт', amount: ''},
        {name: 'РП', value: 'шт', amount: ''},
        {name: 'ЩР', value: 'шт', amount: ''},
        {name: 'Приборы учёта электроэнергии марки', value: 'шт', amount: ''},
        {name: 'ВНС (техническая документация)', value: 'шт', amount: ''},
        {name: 'КНС (техническая документация)', value: 'шт', amount: ''},
        {name: 'Слаботочные сети (внутридомовые)', value: 'Пм', amount: ''},
        {name: 'ЦТП (техническая документация)', value: 'шт', amount: ''},
        {name: 'Балансировочные клапана', value: 'шт', amount: ''},
        {name: 'Количество пожарных гидрантов', value: 'шт', amount: ''},
        {name: 'Количество пожарных рукавове)', value: 'шт', amount: ''},
        {name: 'Схемы с указанием гарантий и контактов подрядных организаций по инженерным сетям', value: 'налич', amount: ''},
        {name: 'Количество антенн операторов сотовой связи', value: 'шт', amount: ''},
        {name: 'Наименования интернет-провайдеров, подключенные в дому (+ТВ и телефония)', value: 'Казах телеком, Beeline', amount: ''},
      ],
      items: [],
      headers: [
        {
          text: 'Наименование',
          sortable: false,
          value: 'name',
        },
        { text: 'Ед.изм', value: 'value', sortable: false, },
        { text: 'Количество / наличие', value: 'amount', sortable: false, },
        { text: 'Действие', value: 'actions', sortable: false, },
      ],
    }
  },
  computed: {
		...mapGetters({
      token: "auth/GET_TOKEN",
		}),

	},
  methods: {
    createPDF (title, ref) {
      var source =  this.$refs[ref];
      let rows = [];
      let columnHeader = ['Наименование', 'Ед.изм', 'Кол-во'];
      let pdfName = title;
      source.items.forEach(element => {
          var temp = [
              element.name,
              element.value,
              element.amount 
          ];
          rows.push(temp);
      });
      
      var doc = new jsPDF();
      var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
      // doc.setFont('PTSans-Regular', 'normal');
      doc.setFontSize(8);
      console.log(doc);
      console.log(doc.getFont());
      console.log(doc.getFontList());
      // doc.text(this.house.apartment_address, 14, 30);
      let address = this.apartment_address
      var header = function () {
        doc.setFontSize(18);
        doc.setTextColor(40);
        doc.setFont('PTSans-Regular', 'normal');
        doc.text(pdfName, pageWidth / 2, 15, {align: 'center'});
        doc.setFontSize(14);
        doc.text(`Адрес дома - ${address}`, pageWidth / 2, 25, {align: 'center'});
      }
      doc.autoTable({
        didDrawPage: header,
        startY: 30,
        headStyles :{fillColor : [46, 184, 92]},
        head: [columnHeader],
        body: rows,
        styles: {
          font: 'PTSans-Regular',    // <-- place name of your font here
          fontStyle: 'normal',
        },
      });

      const tableBottom = doc.autoTable.previous.finalY;
      doc.setFont('PTSans-Regular', 'normal');
      doc.setFontSize(12);
      doc.text('Сдал ________________________________', 15, tableBottom + 15);
      doc.text('Принял ______________________________', 15, tableBottom + 25);
      doc.save(pdfName + '.pdf');
    },
		closeModal() {
			this.dialog = false;
			this.$emit("close-modal");
		},
    deleteItem (item) {
      console.log('this.tab', this.tab)
      if(this.tab == 0) {
        this.editedIndex = this.items.inventories.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
        console.log('this.tab', this.tab)
      } else if(this.tab == 1) {
        this.editedIndex = this.items.improvements.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
        console.log('this.tab', this.tab)
      }
    },
    deleteItemConfirm () {
      if(this.tab == 0) {
        this.items.inventories.splice(this.editedIndex, 1)
        this.closeDelete()
        this.data.inventories = this.items.inventories
        this.data.improvements = this.items.improvements
        this.editPassport()
      } else if(this.tab == 1) {
        this.items.improvements.splice(this.editedIndex, 1)
        this.closeDelete()
        this.data.inventories = this.items.inventories
        this.data.improvements = this.items.improvements
        this.editPassport()
      }
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    async getPassport() {
      this.loading = true
      try {
        const response = (await axios.get(`${process.env.VUE_APP_API_URL}/houses/${this.house_id}/passport`, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `${this.token}` 
          }
        }));  
        this.items = response.data
        if(!this.items.inventories && !this.items.improvements) {
          this.createPassport()
        } else {
          this.loading = false
        }
        
        console.log('response', response)
      } catch(error) {
        console.log('error', error)
      }
    },
    async createPassport() {
      let data = this.items
      data._method = "patch"
      data.improvements = this.improvementsItems
      data.inventories = this.inventoriesItems
      console.log('data', data)
      try {
        const response = (await axios.post(`${process.env.VUE_APP_API_URL}/houses/${this.house_id}/passport`, data, {
          headers: {
            Authorization: `${this.token}` 
          }
        }));
        this.items = response.data
        this.loading = false
        console.log('response', response)
      } catch(error) {
        console.log('error', error)
      }
    },
    addField() {
      console.log("_id" in this.items)
      if(this.tab == 0) {
        if('inventories' in this.items) {
          this.data.inventories = this.items.inventories
          console.log("data,", this.data)
          this.data.inventories.push(this.params)
          this.editPassport()
          console.log('this.items.fields', "inventories" in this.items)
        } 
      } else if(this.tab == 1) {
        if('improvements' in this.items) {
          this.data.improvements = this.items.improvements
          console.log("data,", this.data)
          this.data.improvements.push(this.params)
          this.editPassport()
          console.log('this.items.fields', "improvements" in this.items)
        }
      }
    },
    async editPassport() {
      try {
        const response = (await axios.post(`${process.env.VUE_APP_API_URL}/houses/${this.house_id}/passport`, this.data, {
          headers: {
            Authorization: `${this.token}` 
          }
        }));  
        this.items = response.data
        this.loading = false
        this.params.name = ''
        this.params.value = ''
        this.params.amount = ''
        console.log('response', response)
      } catch(error) {
        console.log('error', error)
      }
    }
  },
  created() {
    console.log('house', this.house_id)
    this.getPassport()
  },
}

</script>