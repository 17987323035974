// * Global variables are stored here

// ? Цвета статусов заявок
export const statusColor = {
	"new": "primary",
	"done": "warning",
	"canceled_by_user": "error",
	"rejected": "error",
	"in_work": "info",
	"paused": "secondary",
	"accepted": "green",
};

export const months = {
	"rus": [
		"Январь",
		"Февраль",
		"Март",
		"Апрель",
		"Май",
		"Июнь",
		"Июль",
		"Август",
		"Сентябрь",
		"Октябрь",
		"Ноябрь",
		"Декабрь",
	],
};

export const monthsShort = {
	"rus": [
		"Янв",
		"Фев",
		"Мар",
		"Апр",
		"Май",
		"Июн",
		"Июл",
		"Авг",
		"Сен",
		"Окт",
		"Ноя",
		"Дек",
	],
};

export const monthsEng = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"November",
	"October",
	"December",
];

export const kskConfirmTypes = [
	{ text: "Все", value: null },
	{ text: "Не подтвержденные", value: 0 },
	{ text: "Подтвержденные", value: 1 },
];

export const priceTypes = [
	{ text: "Фиксированная цена", value: "fix_price" },
	{ text: "Плавающая цена", value: "floating_price" },
	{ text: "Договорная цена", value: "negotiable" },
];
