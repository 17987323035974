<template>
	<div class="houses-item-info d-flex flex-column">
		<header
			class="houses-item-info__header border-bottom pa-5 py-6 d-flex justify-space-between align-center"
		>
			<TextBox :loading="loading">
				{{ item ? item.apartment_address : "" }}
			</TextBox>
			<div class="d-flex">
				<Button class="mr-12" color="primary" small @click.native="showHouseAcc = true">
					Приёмка дома 
				</Button>
				<!-- <Button class="mr-12" small @click.native="showBoardModal = true">
					{{ boardBtn }}
				</Button> -->
				<Button class="mr-12" small @click.native="showPorchModal = true">
					Подъезды
				</Button>
				<Button color="red" text small @click.native="showConfirmModal = true">
					Удалить
				</Button>
				<Button text small @click.native="showEditModal = true" :disabled="loading">
					Редактировать
				</Button>
			</div>
		</header>

		<article class="body">
			<div class="body-left border-right pa-3">
				<HousesItemBoard />

				<div class="pa-5">
					<v-alert v-if="alert" dense outlined :type="alert.color">
						{{ alert.message }}
					</v-alert>
				</div>
			</div>

			<HousesItemInfoRight
				:item="item"
				:loading="loading"
				@update-info="loadData"
			/>
		</article>

		<HousesItemBoardModal
			v-if="showBoardModal"
			@close="showBoardModal = false"
		/>

		<!-- Редактирование информации о доме -->
		<HousesTableModalNext
			v-if="showEditModal"
			:house-data="item"
			:is-editing="true"
			@close-modal="closeModal"
		/>

		<!-- Подтверждение удаления дома -->
		<ModalConfirm
			v-if="showConfirmModal"
			:title="'Удаление дома'"
			@close-modal="deleteHouse"
		>
			<template #body>
				<div class="d-flex flex-column justify-center text-center align-center">
					<span>Вы действительно хотите удалить дом по адресу</span>
					<strong>{{ item.apartment_address }}?</strong>
				</div>
			</template>
		</ModalConfirm>

		<HousesAcceptanceModal
			v-if="showHouseAcc"
			:house_id="item.id"
			:apartment_address="item.apartment_address"
			@close-modal="closeHouseAcc"
			>
		</HousesAcceptanceModal>
		<HousesPorchModal
			v-if="showPorchModal"
			:house-id="item.id"
			@close-modal="showPorchModal = false"
			>
		</HousesPorchModal>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

import ModalConfirm from "@/components/app/ModalConfirm";
import HousesItemBoard from "@/components/HousesItemBoard";
import HousesItemBoardModal from "@/components/HousesItemBoardModal";
import HousesItemInfoRight from "@/components/HousesItemInfoRight";
import HousesTableModalNext from "@/components/HousesTableModalNext";
import HousesAcceptanceModal from "@/components/HousesAcceptanceModal";
import HousesPorchModal from "@/components/HousesPorchModal";

export default {
	name: "HousesItemInfo",
	components: {
		HousesItemBoard,
		HousesItemBoardModal,
		HousesItemInfoRight,
		HousesTableModalNext,
		ModalConfirm,
		HousesAcceptanceModal,
		HousesPorchModal
	},
	data() {
		return {
			alert: null,
			showHouseAcc: false,
			chips: [],
			id: this.$route.params.id,
			item: null,
			loading: true,
			showConfirmModal: false,
			showEditModal: false,
			showBoardModal: false,
			showPorchModal: false,
		};
	},
	computed: {
		...mapGetters({
			ksk_info: "ksk/GET_KSK_INFO",
			houses: "ksk/GET_HOUSES",
		}),
		boardBtn() {
			return (
				// (this.item?.entrances.length ? "Редактировать" : "Создать") +
				// " шахматку"
				"Создать шахматку"
			);
		},
	},
	watch: {
		// ? Ручное отслеживание маршрута, чтобы обновлять данные о сотрудниках
		"$route"() {
			this.id = this.$route.params.id;
			this.loadData();
		},
	},
	methods: {
		closeHouseAcc() {
			this.showHouseAcc = false;
		},
		closeModal(isConfirmed) {
			if (isConfirmed === true) this.loadData();
			this.showEditModal = false;
		},

		async deleteHouse(confirm) {
			console.log(confirm);
			this.alert = null;
			this.showConfirmModal = false;
			if (!confirm) return;
			try {
				const res = await this.$api.ksk.unbind_house(this.id);
				console.log(res);
				this.alert = {
					color: "success",
					message: "Дом удален",
				};
				setTimeout(() => this.$router.push("/houses"), 500);
			} catch (error) {
				const message = "Не удалось удалить дом. Попробуйте еще раз";
				this.alert = {
					color: "error",
					message: error.message || message,
				};
				console.log(error);
			}
		},
		async loadData() {
			this.loading = true;
			this.item = await this.$api.ksk.load_house_by_id(this.$route.params.id);
			this.$store.commit("board/SET_ENTRANCES", this.item.entrances);
			this.loading = false;
		},
	},
	async mounted() {
		try {
			// this.$store.dispatch("ksk/load_ksk_info");
			this.loadData();
		} catch (error) {
			console.log(error);
		}
	},
};
</script>

<style lang="scss" scoped>
.body {
	display: grid;
	grid-template-columns: minmax(0, 3fr) minmax(0, 1fr);
	height: 100% !important;
}
.houses-item-info {
	&__header {
		border-bottom-style: inset;
	}
}
::v-deep .v-skeleton-loader__text {
	margin: 0 !important;
}
</style>
