<template>
	<div class="d-flex justify-space-between flex-sm-column mb-3">
		<label class="caption font-weight-regular black--text">
			{{ label }}
		</label>
		<span class="caption font-weight-light">
			<slot>
				{{ loading ? "---" : getText() }}
			</slot>
		</span>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: "HousesItemInfoRightItem",

	props: {
		item: {
			validator: e => e === null || typeof e === "object",
			default: () => {},
		},
		loading: {
			type: Boolean,
			default: false,
		},
		label: {
			type: String,
			default: "",
		},
		text: {
			type: String,
			default: "",
		},
		field: {
			type: String,
			default: "",
		},
	},

	computed: {
		...mapGetters({
			catalog: "catalog/GET_CATALOG",
		}),
	},

	methods: {
		getText() {
			if (this.text) return this.text;
			if (!this.item) return "";
			if (this.field === "category_residential_complex_id")
				return this.getComplexCategory();
			return this.item[this.field] || "---";
		},

		getComplexCategory() {
			return (
				this.catalog.category_residential_complex.find(
					e => e.id === this.item.category_residential_complex_id
				)?.name || "---"
			);
		},
	},
};
</script>

<style></style>
