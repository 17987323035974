<template>
	<aside class="houses-item-list d-flex flex-column flex-grow-1">
		<div class="border d-flex justify-space-between align-center py-5 px-3">
			<v-btn
				class="pa-0"
				link
				depressed
				plain
				@click="$router.push({ path: '/houses', query: $route.query })"
			>
				<v-icon color="grey darken-1" small>mdi-chevron-left</v-icon>
				<span class="caption">Вернуться к списку</span>
			</v-btn>
		</div>
		<!-- Блок спиннера на всю высоту -->
		<div
			v-if="loading"
			class="h-100 border-right d-flex flex-column align-center pa-3 flex-sm-grow-1 overflow-y-auto"
		>
			<v-progress-circular
				size="20"
				indeterminate
				color="green"
			></v-progress-circular>
		</div>

		<!-- Спиннер для загрузки новых записей  -->
		<div v-if="loadingMore" class="d-flex justify-center">
			<v-progress-linear
				absolute
				indeterminate
				color="green"
			></v-progress-linear>
		</div>

		<!-- Записи -->
		<div class="border-right flex-grow-1">
			<ul class="list d-flex flex-column align-center pa-3" @scroll="onScroll">
				<HousesItemListCard
					v-for="(item, i) of items"
					:key="item.id"
					:item="item"
					:id="item._id"
					@change-page="changePage(i)"
				/>
			</ul>
		</div>
	</aside>
</template>

<script>
import { mapGetters } from "vuex";
import DatePicker from "@/components/app/DatePicker";
import HousesItemListCard from "@/components/HousesItemListCard";

export default {
	name: "HousesItemList",
	components: {
		DatePicker,
		HousesItemListCard,
	},
	data() {
		return {
			items: [],
			lastPage: null,
			loading: false,
			loadingMore: false,
			scrolledPages: [],
			page: +this.$route.query.page || 1,
		};
	},
	computed: {
		...mapGetters({
			ksk_id: "auth/GET_KSK_ID",
			base_role: "auth/GET_USER_ROLE",
		}),
	},
	methods: {
		changePage(item) {
			this.$router.replace({ query: { page: item.page } });
		},

		onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
			const firstPage = this.items[0].page;
			const lastPage = this.items[this.items.length - 1].page;

			if (
				scrollTop + clientHeight >= scrollHeight &&
				lastPage <= this.lastPage
			) {
				this.loadData();
			} else if (scrollTop === 0 && firstPage - 1 >= 1) {
				this.loadData(false);
			}
		},

		scrollIntoView(id) {
			document.getElementById(id).scrollIntoView({
				behavior: "smooth",
			});
		},

		resetFilters() {
			this.page = 1;
			this.scrolledPages = [];
			this.$router.replace({ query: { page: 1 } });
			this.loadData(undefined, true);
		},

		async loadData(toDown = true, update = false) {
			try {
				let newPage;

				if (update) newPage = this.page;
				else if (toDown) newPage = this.items[this.items.length - 1].page + 1;
				else newPage = this.items[0].page - 1;

				if (this.scrolledPages.includes(newPage)) {
					return;
				}

				if (update) {
					this.loading = true;
				} else {
					this.loadingMore = true;
				}

				let params;
				params = {
					...this.$route.query,
					page: newPage,
					paginate: true,
				}
				if(this.base_role !== 'administrator') {
					params.ksk_ids = [this.ksk_id]
				}
				const res = await this.$api.ksk.load_houses(params);
				console.log(res);
				res.data = res.records.map(e => ({ ...e, page: newPage }));
				this.lastPage = res.pages;

				if (update) {
					this.items = [...res.data];
				} else if (toDown) {
					this.scrolledPages.push(newPage);
					this.items = [...this.items, ...res.data];
				} else {
					this.scrolledPages.unshift(newPage);
					this.items = [...res.data, ...this.items];
				}
			} catch (error) {
				console.log(error);
			} finally {
				this.loading = this.loadingMore = false;
			}
		},
	},

	async created() {
		await this.loadData(undefined, true);
		if (this.items.length < 10) {
			if (this.page - 1 >= 1) {
				this.page--;
				await this.loadData(false);
			}
		}
		// const { _id } = this.items.find(e => e._id === this.$route.params.id);
		// setTimeout(() => {
		// 	this.scrollIntoView(_id);
		// }, 100);
	},
};
</script>

<style lang="scss" scoped>
.houses-item-list {
	height: 100%;
	position: relative;
	overflow: hidden;
}
.h-100 {
	height: 100vh;
}
.list {
	max-height: 930px;
	overflow-y: overlay;
}
</style>
