<template>
	<div class="houses-item" ref="item">
		<HousesItemList :updateList.sync="updateList" />
		<HousesItemInfo @update-list="updateList = true" />
	</div>
</template>

<script>
import HousesItemList from "@/components/HousesItemList";
import HousesItemInfo from "@/components/HousesItemInfo";

export default {
	name: "HousesItem",

	components: {
		HousesItemList,
		HousesItemInfo,
	},

	data() {
		return {
			updateList: false,
		};
	},
};
</script>

<style lang="scss" scoped>
.houses-item {
	height: 100%;
	background: #ffffff;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
	display: grid;
	grid-template-columns: 1fr 4fr;
}
</style>
