<template>
	<v-col class="houses-item-board-apartments">
		<v-row class="houses-item-board-apartments__row">
			<v-col
				cols="1"
				class="houses-item-board-apartments__floors d-flex flex-column text-center"
			>
				<span
					v-for="floor of entrance.floors"
					:key="floor.name"
					style="height: 50px;"
					class="mb-3 d-flex align-center justify-center"
				>
					{{ floor.name.split(" ")[1] }}
				</span>
			</v-col>

			<v-col
				cols="11"
				class="houses-item-board-apartments__table d-flex flex-column scrollbar"
			>
				<div
					v-for="floor of entrance.floors"
					:key="entrance.name + floor.name"
					class="d-flex align-center mb-3"
				>
					<HousesItemBoardApartmentsItem
						v-for="apt of floor.apartments"
						:key="apt.name"
						:item="apt"
					/>
				</div>
			</v-col>
		</v-row>
	</v-col>
</template>

<script>
import HousesItemBoardApartmentsItem from "@/components/HousesItemBoardApartmentsItem";
import { mapGetters } from "vuex";

export default {
	name: "HousesItemBoardApartments",

	components: {
		HousesItemBoardApartmentsItem,
	},

	data() {
		return {};
	},

	computed: {
		...mapGetters({
			cEntrance: "board/GET_SELECTED_PORCH",
		}),
		entrance() {
			console.log(this.cEntrance);
			return this.cEntrance || [];
		},
	},
};
</script>

<style lang="scss" scoped>
.houses-item-board-apartments {
	position: relative;
	overflow: auto;
	// max-height: 400px;
	&__table {
		overflow-x: scroll;
		&::-webkit-scrollbar-thumb {
			border: 1px solid #bfbfbf !important;
			background-color: #bfbfbf !important;
		}
	}
}
</style>
