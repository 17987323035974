<template>
	<div class="body-right pa-5">
		<ImageSelect :value.sync="background" @update:value="changeImage" />

		<HousesItemInfoRightItem
			v-for="e of items"
			:loading="loading"
			:key="e.field"
			v-bind="e"
			:item="item"
		/>

		<span class="subtitle-1 mb-3">Орган управления</span>

		<HousesItemInfoRightItem
			v-for="e of itemsApw"
			:loading="loading"
			:key="e.field"
			v-bind="e"
			:item="item"
		/>
	</div>
</template>

<script>
import ImageSelect from "@/components/app/ImageSelect";
import HousesItemInfoRightItem from "@/components/HousesItemInfoRightItem";

export default {
	name: "HousesItemInfoRight",

	components: {
		ImageSelect,
		HousesItemInfoRightItem,
	},

	props: {
		item: {
			validator: e => e === null || typeof e === "object",
			default: () => {},
		},
		loading: {
			type: Boolean,
			required: true,
		},
	},

	data: function() {
		return {
			background: this.item?.background || null,
			items: [
				{ label: "Адрес", field: "apartment_address" },
				// { label: "Название ЖК", field: "name" },
				// { label: "Категория ЖК", field: "category_residential_complex_id" },
				{ label: "РКА", field: "rca" },
				// { label: "Район", field: "district" },
				// { label: "Почтовый индекс", field: "postcode" },
				{ label: "Кол-во квартир", field: "apartments_count" },
				{ label: "Кол-во НП", field: "actual_number_non_residential_premises" },
				{ label: "Кол-во паркомест", field: "actual_number_parking_place" },
				// { label: "Год постройки", field: "year_construction" },
			],
			itemsApw: [
				{ label: "Наименование ОСИ", field: "name_apw" },
				{ label: "Адрес ОСИ", field: "address_apw" },
				{ label: "Телефон ОСИ", field: "phone_apw" },
				{ label: "Наименование УК", field: "name_ksk" },
				{ label: "Адрес УК", field: "address_ksk" },
				{ label: "Телефон УК", field: "phone_ksk" },
				// { label: "БИН", field: "bin_apw" },
				// { label: "Председатель", field: "fio_chairman" },
				// { label: "Номер телефона", field: "phone_chairman" },
			],
		};
	},

	// watch: {
	// 	item() {
	// 		this.background = this.item.background;
	// 	},
	// },
	watch: {
		item() {
			this.getImage()
		},
	},

	methods: {
		async changeImage(e) {
			try {
				console.log('ch', this.background)
				const formData = new FormData();
				formData.append('original_name', this.background[0].name);
				formData.append('file', this.background[0]);

				const res = await this.$api.ksk.load_files(formData);
				console.log('res', res)
				await this.$api.ksk.edit_house(this.$route.params.id, {background_image_id: res.id, _method: "patch"});
				this.$emit("update-info");
			} catch (error) {
				console.error(error);
			}
		},
		async getImage() {
			if(this.item?.background_image) {
				try {
					const response = await this.$api.ksk.load_file(this.item.background_image?.id);
					console.log('res', response)
					const blob = new Blob([response], { type: 'application/octet-stream' });
					const blobUrl = URL.createObjectURL(blob);
					this.background = blobUrl;

				} catch (error) {
					console.error("Ошибка загрузки изображения:", error);
				}
			} else {
				this.background = null
			}
		}
		// async changeImage(e) {
		// 	try {
		// 		const data = { ...this.item, background: this.background[0] };
		// 		const formData = data.toFormData();
		// 		await this.$api.ksk.edit_house(this.$route.params.id, formData);
		// 		this.$emit("update-info");
		// 	} catch (error) {
		// 		console.error(error);
		// 	}
		// },
	},
};
</script>

<style></style>
