<template>
	<v-row class="houses-item-board ma-0">
		<HousesItemBoardSearch class="mb-4" />

		<HousesItemBoardFloors />
	</v-row>
</template>

<script>
import HousesItemBoardFloors from "@/components/HousesItemBoardFloors";
import HousesItemBoardSearch from "@/components/HousesItemBoardSearch";

export default {
	name: "HousesItemBoard",

	components: {
		HousesItemBoardSearch,
		HousesItemBoardFloors,
	},
};
</script>

<style lang="scss" scoped>
.houses-item-board {
	overflow: hidden;
	max-width: 100%;
}
</style>
