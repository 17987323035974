<template>
	<v-dialog :value="value" persistent max-width="1200px">
		<v-card>
			<v-card-title
				class="d-flex justify-space-between border-bottom primary pt-2 pb-1"
			>
				<span class="subtitle-1 white--text">Создание шахматки</span>
				<v-btn color="dark" @click="handleClose(false)" icon>
					<v-icon color="white">mdi-close</v-icon>
				</v-btn>
			</v-card-title>

			<v-card-text class="pt-4">
				<v-row>
					<v-col cols="12" class="d-flex align-center">
						<TextField
							:value.sync="porchAmount"
							class="mr-5"
							label="Укажите кол-во подъездов"
							row
							type="number"
							input-width="50"
							@keyup.enter.native="handlePorchCreate"
						/>

						<Button small @click.native="handlePorchCreate">
							Создать
						</Button>
					</v-col>

					<v-col cols="12">
						<p v-if="!data.entrances.length">
							Для отображения укажите количество подъездов
						</p>
						<div v-else class="porch-list scrollbar scrollbar mb-3">
							<TextField
								v-for="i in data.entrances.length"
								:value.sync="data.entrances[i - 1].name"
								:key="i"
								:active="porchChosen === i - 1"
								:placeholder="`Подъезд ${i}`"
								:error="!data.entrances[i - 1].name"
								class="mr-3"
								input-width="150"
								min="0"
								validate-on-blur
								@focus="handlePorchFocus(i - 1)"
							/>
						</div>

						<div v-if="data.entrances.length" class="d-flex align-center mb-7">
							<TextField
								:value.sync="data.entrances[porchChosen].first_floor"
								class="mr-8"
								label="Нумерация квартир начинается с ... этажа"
								row
								type="number"
								input-width="50"
								min="0"
								validate-on-blur
								:error="!data.entrances[porchChosen].first_floor"
								:disabled="!data.entrances.length"
								@keyup.enter.native="handleFloorsCreate"
							/>
							<TextField
								:value.sync="data.entrances[porchChosen].last_floor"
								label="до ... этажа"
								row
								class="mr-8"
								type="number"
								input-width="50"
								min="0"
								validate-on-blur
								:error="!data.entrances[porchChosen].last_floor"
								:disabled="!data.entrances.length"
								@keyup.enter.native="handleFloorsCreate"
							/>
							<Button
								class="mr-4"
								small
								:disabled="!data.entrances.length"
								@click.native="handleFloorsCreate"
							>
								Добавить
							</Button>
							<AlertText v-if="alertFloors" error>
								{{ alertFloors }}
							</AlertText>
						</div>

						<article
							v-if="showFloors"
							class="floors d-flex scrollbar no-pagination"
						>
							<div
								v-for="(e, i) of data.entrances"
								:key="e.name"
								class="floors__container mr-7"
							>
								<div
									v-if="!e.floors || !e.floors.length"
									class="text-caption"
									style="min-width: 100px;"
								>
									Добавьте этажи для
									<b>{{ e.name }}</b>
								</div>
								<ul v-else class="d-flex flex-column pa-0">
									<li
										v-for="(floor, j) of e.floors"
										:key="`${e.name}-${floor.name}`"
										class="d-flex align-center mb-3"
									>
										<span class="d-flex mr-3" style="min-width: 70px;">
											{{ floor.name }}
										</span>
										<TextField
											:value.sync="data.entrances[i].floors[j].first_apartment"
											:active="porchChosen === i"
											input-width="60"
											validate-on-blur
											:error="
												+data.entrances[i].floors[j].first_apartment >
													+data.entrances[i].floors[j].last_apartment ||
													!+data.entrances[i].floors[j].first_apartment ||
													+data.entrances[i].floors[j].first_apartment < 1
											"
											@focus="porchChosen = i"
										/>
										<span class="mx-2">-</span>
										<TextField
											:value.sync="data.entrances[i].floors[j].last_apartment"
											:active="porchChosen === i"
											input-width="60"
											validate-on-blur
											:error="
												+data.entrances[i].floors[j].first_apartment >
													+data.entrances[i].floors[j].last_apartment ||
													!+data.entrances[i].floors[j].last_apartment ||
													+data.entrances[i].floors[j].last_apartment < 1
											"
											@focus="porchChosen = i"
										/>
									</li>
									<li class="floors__actions d-flex justify-end">
										<v-tooltip bottom color="primary" open-delay="500">
											<template v-slot:activator="{ on, attrs }">
												<v-btn
													color="primary"
													icon
													v-bind="attrs"
													v-on="on"
													:disabled="isDeletingLastApartment === i"
													@click="addLastApartment(i)"
												>
													<v-icon>mdi-plus</v-icon>
												</v-btn>
											</template>
											<span>Добавить этаж</span>
										</v-tooltip>

										<v-tooltip bottom color="red" open-delay="500">
											<template v-slot:activator="{ on, attrs }">
												<v-btn
													color="red"
													icon
													v-bind="attrs"
													v-on="on"
													:disabled="isDeletingLastApartment === i"
													@click="deleteLastApartment(i)"
												>
													<v-icon>mdi-minus</v-icon>
												</v-btn>
											</template>
											<span>Удалить последний этаж</span>
										</v-tooltip>
									</li>
								</ul>
							</div>
						</article>
					</v-col>
				</v-row>

				<v-overlay absolute opacity="0.5" :value="isLoading">
					<v-progress-circular size="30" indeterminate></v-progress-circular>
				</v-overlay>
			</v-card-text>

			<v-card-actions class="d-flex justify-end border-top">
				<AlertText
					v-if="alertBottom"
					:type="alertBottom.error ? 'error' : 'success'"
					class="mr-3"
				>
					{{ alertBottom.text || alertBottom }}
				</AlertText>
				<Button :loading="isLoading" @click.native="handleSubmit">
					{{ this.cEntrances ? "Сохранить" : "Создать" }}
				</Button>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	name: "HousesItemBoardModal",

	props: {
		value: {
			type: Boolean,
			default: true,
		},
	},

	data() {
		return {
			alertBottom: null,
			alertFloors: null,
			isLoading: false,
			house_id: this.$route.params.id,
			porchAmount: null,
			porchChosen: 0,
			isDeletingLastApartment: null,
			data: {
				entrances: [],
			},
		};
	},

	computed: {
		...mapGetters({
			cEntrances: "board/GET_ENTRANCES",
		}),
		showFloors() {
			return this.porchAmount || this.data.entrances.length;
		},
	},

	watch: {
		// cEntrances(v) {
		// 	console.log(v);
		// 	this.setEntrances();
		// },
	},

	methods: {
		validateFloors(f, l) {
			if (f < 0 || l < 0) {
				return !(this.alertFloors = "Укажите корректные значения этажей");
			}
			if ((f !== 0 && !f) || !l) {
				return !(this.alertFloors = "Укажите первый и последний этаж");
			}
			if (+l < +f) {
				return !(this.alertFloors =
					"Первый этаж не может быть меньше последнего");
			}
			return true;
		},

		async deleteFloors(floors, from, to) {
			for (let i = from; i < to; i++) {
				if (!floors[i]._id) continue;
				await this.$api.ksk.delete_floor(floors[i]._id);
			}
		},

		addLastApartment(index) {
			const floors = this.data.entrances[index].floors;
			const len = floors.length;
			const name =
				"Этаж " + (len ? +floors[len - 1].name.split(" ")[1] + 1 : 1);
			this.data.entrances[index].floors.push({
				name,
				first_apartment: null,
				last_apartment: null,
			});
			if (!len)
				this.data.entrances[index].first_floor = this.data.entrances[
					index
				].last_floor = 1;
			else this.data.entrances[index].last_floor += 1;
		},

		async deleteLastApartment(index) {
			const floors = this.data.entrances[index].floors;
			const len = floors.length;
			if (!len) return;
			if (floors[len - 1]._id) {
				try {
					this.isDeletingLastApartment = index;
					await this.$api.ksk.delete_floor(floors[len - 1]._id);
				} catch (error) {
					console.error(error);
				} finally {
					this.isDeletingLastApartment = null;
				}
			}
			this.data.entrances[index].floors.pop();
			if (!floors.length)
				this.data.entrances[index].first_floor = this.data.entrances[
					index
				].last_floor = null;
			else this.data.entrances[index].last_floor -= 1;
		},

		handleClose(e) {
			this.$emit("update:value", e);
			this.$emit("close");
		},

		async handleFloorsCreate() {
			this.alertFloors = null;

			const porchChosen = this.porchChosen;
			let entrance = this.data.entrances[porchChosen];

			let { first_floor, last_floor } = entrance;
			first_floor = +first_floor;
			last_floor = +last_floor;

			if (!this.validateFloors(first_floor, last_floor)) return;

			const addFloors = (amount, from) =>
				Array.from(Array(amount).keys()).map((_, i) => ({
					name: `Этаж ${from + i + 1}`,
					first_apartment: null,
					last_apartment: null,
				}));

			let { floors } = entrance;

			if (!floors?.length) {
				this.data.entrances[porchChosen].floors = addFloors(
					last_floor - first_floor + 1,
					first_floor - 1
				);
				this.data = { ...this.data };
				return;
			}

			const prevF = +floors[0].name.split(" ")[1];
			const prevL = +floors[floors.length - 1].name.split(" ")[1];

			if (prevF < first_floor) {
				await this.deleteFloors(floors, 0, first_floor - prevL);
				entrance.floors = entrance.floors.slice(first_floor - prevL - 1);
			} else {
				const arr = addFloors(prevF - first_floor, first_floor - 1);
				entrance.floors = [...arr, ...entrance.floors];
			}

			if (prevL < last_floor) {
				const arr = addFloors(last_floor - prevL, prevL);
				entrance.floors = [...entrance.floors, ...arr];
			} else if (prevL > last_floor) {
				const from = floors.length - prevL + last_floor;
				await this.deleteFloors(floors, from, floors.length);
				entrance.floors = entrance.floors.slice(0, from);
			}

			this.data = { ...this.data };
		},

		async deletePorches(amount) {
			const entrances = [...this.data.entrances];
			let i = entrances.length - 1;
			while (amount && i >= 0) {
				const { _id } = entrances[i];
				if (_id) await this.$api.ksk.delete_entrance(_id);
				i--;
				amount--;
			}
		},

		handlePorchCreate() {
			let amount,
				{ length } = this.data.entrances;

			if (!this.cEntrances) amount = +this.porchAmount;
			else amount = +this.porchAmount - length;

			if (amount < 0) {
				this.deletePorches(amount * -1);
				const newLen = length + amount;
				// if porch to be deleted is chosen then choose the last porch
				if (this.porchChosen >= newLen) {
					this.porchChosen = 0;
				}

				this.data.entrances = this.data.entrances.splice(0, newLen);
				return;
			}

			const arr = Array(amount)
				.fill("")
				.map((_, i) => ({
					name: `Подъезд ${length + i + 1}`,
					first_floor: null,
					last_floor: null,
				}));

			this.data.entrances = [...this.data.entrances, ...arr];
			this.data = { ...this.data };
		},

		handlePorchFocus(i) {
			console.log("focus", i);
			this.porchChosen = i;
		},

		checkFloors() {
			let i = 0;
			for (const e of this.data.entrances) {
				if (!e.first_floor || !e.last_floor) {
					this.porchChosen = i;
					return false;
				}
				i++;
			}
			return true;
		},

		checkApartments() {
			for (const e of this.data.entrances) {
				const { floors } = e;
				if (!floors) continue;
				for (const f of floors) {
					const fa = +f.first_apartment;
					const la = +f.last_apartment;
					if (fa > la || !la || la < 1 || !fa || fa < 1) return false;
				}
			}
			return true;
		},

		async handleSubmit() {
			this.alertBottom = null;

			if (!this.checkFloors()) {
				this.alertBottom = {
					error: true,
					text: "Укажите этажи",
				};
				return;
			}

			try {
				this.isLoading = true;
				let { entrances } = this.data;

				// Create or edit entrances if already exists
				for (const e of entrances) {
					if (e._id) await this.$api.ksk.edit_entrance({ id: e._id, data: e });
					else
						await this.$api.ksk.add_entrance({
							house_id: this.house_id,
							...e,
						});
				}
				await this.$store.dispatch("board/load", this.house_id);
				this.data.entrances = entrances = entrances.map((e, i) => ({
					...e,
					"_id": this.cEntrances[i]._id,
				}));

				for (const e of entrances) {
					console.log("creating floor", e);
					await this.$api.ksk.add_floors_many({
						first_floor: e.first_floor?.toString() || "",
						last_floor: e.last_floor?.toString() || "",
						entrance_id: e._id,
					});
				}

				console.log("loading entrs with floor ids");

				await this.$store.dispatch("board/load", this.house_id);

				if (!this.checkApartments()) {
					return (this.alertBottom = {
						error: true,
						text: "Введите корректные номера квартир",
					});
				}

				entrances = entrances.map((e, i) => ({
					...e,
					floors: e.floors.map((f, j) => ({
						...f,
						first_apartment: f.first_apartment || null,
						last_apartment: f.last_apartment || null,
						floor_id: this.cEntrances[i].floors[j]._id,
					})),
				}));

				console.log("creating apts", entrances);
				for (const e of entrances) {
					const { floors } = e;
					for (const f of floors) {
						if (!f.apartments) continue;
						for (const a of f.apartments) {
							await this.$api.ksk.delete_board_apartment(a._id);
						}
					}
					await this.$api.ksk.add_apartments_many({
						floors,
						house_id: this.house_id,
						entrance_id: e._id,
					});

					await this.$store.dispatch("board/load", this.house_id);
					this.setEntrances();
				}

				if (this.cEntrances) this.alertBottom = "Изменения сохранены";
				else this.alertBottom = "Шахматка создана";
			} catch (e) {
				console.error(e);
				this.alertBottom = {
					text: "Не удалось сохранить изменения",
					error: true,
				};
			} finally {
				this.isLoading = false;
			}
		},

		setEntrances() {
			if(this.cEntrances) {
				this.data.entrances = [...this.cEntrances];
				if (!this.cEntrances.length) return;
				console.log(this.data.entrances);

				this.porchAmount = this.cEntrances.length || null;
				this.data.entrances = this.data.entrances.map(e => {
					let { floors } = e;
					floors = floors.map(f => {
						if (!f.apartments.length) return f;

						const first_apartment = f.apartments[0].apartment_number || null,
							last_apartment =
								f.apartments[f.apartments.length - 1].apartment_number || null;
						return { ...f, first_apartment, last_apartment };
					});
					const first_floor = +floors[0]?.name.split(" ")[1] || null;
					const last_floor =
						+floors[floors.length - 1]?.name.split(" ")[1] || null;
					return { ...e, floors, first_floor, last_floor };
				});
				console.log(this.data.entrances);
			}
		}
	},

	created() {
		this.setEntrances();
	},
};
</script>

<style lang="scss" scoped>
@mixin greyScrollbar() {
	&::-webkit-scrollbar-thumb {
		border: 1px solid #bfbfbf !important;
		background-color: #bfbfbf !important;
	}
}
.porch-list {
	display: flex;
	overflow-x: scroll;
	padding-bottom: 15px;
	@include greyScrollbar;
}
.floors {
	overflow: scroll;
	max-height: 300px;
	padding-bottom: 15px;
	@include greyScrollbar;
}
</style>
