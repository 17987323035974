<template>
	<v-dialog v-model="dialog" persistent max-width="900px">
		<v-card>
			<!-- Header -->
			<v-card-title
				class="d-flex justify-space-between border-bottom primary pt-2 pb-1"
			>
				<span class="subtitle-1 white--text">
					<span>Подъезды</span>
				</span>
				<v-btn color="dark" @click="closeModal" icon>
					<v-icon color="white">mdi-close</v-icon>
				</v-btn>
			</v-card-title>

			<!-- Progress bar -->
			<v-progress-linear
				v-if="loading"
				color="primary"
				height="5"
				indeterminate
			></v-progress-linear>

			<!-- Body -->
			<v-card-text>
				<v-container>
					<v-alert
						v-if="alert"
						class="mb-0"
						:type="alert.color"
						:color="alert.color"
						outlined
						dense
					>
						{{ alert.message }}
					</v-alert>
					<v-list>
						<v-list-item>
							<v-list-item-content>
								<v-col cols="6">
									<v-list-item-title class="font-weight-bold">Название</v-list-item-title>
								</v-col>
								<v-col cols="6">
									<v-list-item-title class="font-weight-bold">Номер</v-list-item-title>
								</v-col>
								<v-divider style="flex: 1 0 100%;"></v-divider>
							</v-list-item-content>
						</v-list-item>
						<v-list-item
							v-for="(entry, index) in data"
							:key="entry.id"
							@mouseover="setActiveEntry(index)"
							@mouseleave="resetActiveEntry"
						>
							<v-list-item-content>
								<v-row>
									<v-col cols="6">
										<v-list-item-title>{{entry.name}}</v-list-item-title>
									</v-col>
									<v-col cols="6">
										<v-list-item-subtitle>{{entry.number}}</v-list-item-subtitle>
									</v-col>
								</v-row>
								<v-divider style="flex: 1 0 100%;"></v-divider>
							</v-list-item-content>
							<v-list-item-action v-if="index === activeIndex">
								<v-btn-toggle>
									<v-btn icon small @click="editEntry(entry)">
										<v-icon>mdi-pencil</v-icon>
									</v-btn>
									<v-btn icon small @click="showModalConfirm = true; item = entry">
										<v-icon>mdi-delete</v-icon>
									</v-btn>
								</v-btn-toggle>
							</v-list-item-action>
						</v-list-item>
						<v-list-item @click="openCreateModal">
							<v-list-item-content>
								<v-list-item-title>
									<v-icon>mdi-plus</v-icon> Добавить новый подъезд
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-container>
			</v-card-text>
			<!-- Footer -->
			<v-card-actions class="pt-0 pb-5 px-8 d-flex justify-end">

			</v-card-actions>
		</v-card>
		<v-dialog v-model="createModal" persistent max-width="500px">
      <v-card>
        <v-card-title>
          {{editPorchId ? 'Редактирование подъезда' : 'Создание нового подъезда'}}
        </v-card-title>
        <v-card-text>
          <v-form ref="createForm" @submit.prevent="createEntry">
            <v-text-field
              v-model="newEntry.name"
              :rules="nameRules"
              label="Имя подъезда"
              required
            ></v-text-field>
            <v-text-field
              v-model="newEntry.number"
              :rules="numberRules"
              label="Номер подъезда"
              required
            ></v-text-field>
            <v-card-actions>
              <v-btn type="submit" color="primary">{{editPorchId ? 'Сохранить' : 'Создать'}}</v-btn>
              <v-btn @click="closeCreateModal" color="error">Отмена</v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
		<!-- Подтверждение удаления -->
		<ModalConfirm
			v-if="showModalConfirm"
			title="Удаление подъезда"
			:text="`Удалить ${item.name}?`"
			@close-modal="deleteEntry"
		/>
	</v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

import ModalConfirm from "@/components/app/ModalConfirm";

export default {
	name: "EntriesTableModal",
	components: {
		ModalConfirm,
	},
	props: {
		houseId: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			alert: null,
			data: {
				name: '',
				id: '',
				house_id: ''
			},
			item: {
				name: '',
				id: ''
			},
			dialog: true,
			loading: true,
			editPorchId: null,
			activeIndex: null,
      createModal: false,
			showModalConfirm: false,
      newEntry: {
        name: "",
        number: "",
      },
      nameRules: [
        (v) => !!v || "Имя подъезда обязательно",
        (v) => v.length <= 10 || "Имя подъезда должно содержать не более 10 символов",
      ],
      numberRules: [
        (v) => !!v || "Номер подъезда обязателен",
        (v) => /^\d{1,3}$/.test(v) || "Номер подъезда должать содержать только числа и не более трех символов",
      ],
		};
	},

	computed: {
		...mapGetters({
			catalog: "catalog/GET_CATALOG",
			houses: "ksk/GET_HOUSES",
		}),
	},

	watch: {
		alert(val) {
			if (!val) return;
			setTimeout(() => {
				this.alert = null;
			}, 3000);
		},
	},
	methods: {
		// Валидация
		customValidation() {
			let message;

			if (!this.data.name) {
				message = "Укажите название въезда";
			} else if (!this.data.device_type_id) {
				message = "Укажите тип устройства";
			} else if (!this.data.house_id ) {
				message = "Укажите адрес";
			}

			if (message) {
				this.alert = {
					color: "error",
					message,
				};
				return false;
			}

			return true;
		},
		async getPorch() {
			try {
				this.data = await this.$api.ksk.load_porch(this.houseId)
				this.loading = false
			} catch (error) {
				console.log('err', error)
			}
		},
		setActiveEntry(index) {
      this.activeIndex = index;
    },
    resetActiveEntry() {
      this.activeIndex = null;
    },
    editEntry(entry) {
			this.createModal = true;
			this.editPorchId = entry.id
			this.newEntry.name = entry.name
			this.newEntry.number = entry.number
    },
    async deleteEntry(isConfirmed) {
			this.showModalConfirm = false;
			if (!isConfirmed) return;

			try {
				await this.$api.ksk.delete_porch(this.item.id)
				this.getPorch()
			} catch (error) {
				console.log('err', error)
				this.alert = {
					color: "error",
					message: error.error.response.data.message || 'Произошла ошибка',
				};
			}
    },
    openCreateModal() {
      this.createModal = true;
    },
    closeCreateModal() {
      this.createModal = false;
			this.editPorchId = null
      this.newEntry = { name: "", number: "" };
    },
    async createEntry() {
			if(this.editPorchId) return this.editPorch()
      if (this.$refs.createForm.validate()) {
				let params = {
					house_id: this.houseId,
					porchs: []
				}
				params.porchs.push(this.newEntry)
				await this.$api.ksk.add_porch(params)
        this.closeCreateModal();
				this.getPorch()
      }
    },
    async editPorch() {
      if (this.$refs.createForm.validate()) {
				this.newEntry._method = 'patch'
				await this.$api.ksk.edit_porch(this.editPorchId, this.newEntry)
        this.closeCreateModal();
				this.getPorch()
      }
    },
		closeModal() {
			this.dialog = false;
			this.$emit("close-modal");
		},
	},

	async created() {
		this.loading = true;
		this.getPorch()
	},
};
</script>

<style lang="scss" scoped></style>
