<template>
  <v-menu offset-y :nudge-top="nudgeTop" :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
			<v-btn
				v-if="!todayButton || disabled"
				:class="classObject"
				text
				depressed
				plain
				:disabled="disabled"
				v-bind="attrs"
				v-on="on"
			>
				<v-icon class="mr-1" color="secondary" small>
					mdi-calendar-blank
				</v-icon>
				<span v-if="date">{{ date | dateFormat }}</span>
				<span v-else>Срок <span class="text-lowercase">исполнения</span></span>
			</v-btn>
			<div v-else class="d-flex align-center">
				<v-tabs class="date-picker" color="secondary" grow background-color="transparent" :slider-color="!tabsClicked ? 'grey' : 'primary'">
					<v-tab
            class="text-simple icon-grey px-2"
						v-bind="attrs"
						v-on="on"
						@click="tabsClicked = true"
					>
							<v-icon class="mr-4" color="secondary" style="font-size: 20px;">
								mdi-calendar-blank
							</v-icon>
							<span class="text-none" v-if="date">
								<span v-if="dateIsToday">{{date | dateFormat}}</span>
								<span v-else>до {{date | dateFormat}}</span>
							</span>
							<span v-else class="text-none">Дата <span class="text-lowercase">исполнения</span></span>
						<!-- </v-btn> -->
					</v-tab>
					<v-divider
						class="mx-2"
						vertical
						style="border-width: 0px thin 0px 1px; height: 16px; background-color: #B5B9BD; min-height: auto; align-self: center;"
					></v-divider>
					<v-tab @click="selectToday(); tabsClicked = true" class="text-none text-simple" style="color: inherit;">
						Сегодня
					</v-tab>
				</v-tabs>
      </div>
    </template>
    <v-card>
      <v-date-picker
        placeholder="Срок исполнения"
        color="primary"
        :allowed-dates="disablePastDates"
        :value="date"
        :close-on-content-click="false"
				:title-date-format="titleDate"
        @input="handleInput"
				style="width: 100%;"
      ></v-date-picker>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: "DatePicker",
  props: {
    allowPastDates: {
      type: Boolean,
      default: true,
    },
    btnClass: {
      type: String,
      default: "subtitle-2 font-weight-medium text-capitalize",
    },
    date: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    nudgeTop: {
      type: Number,
      default: 0,
    },
    todayButton: {
      type: Boolean,
      default: false,
    },
  },
	data() {
		return {
			tabsClicked: false,
		}
	},
  computed: {
    classObject() {
      const classes = this.btnClass.split(" ");
      const obj = {};
      classes.forEach(c => (obj[c] = true));
      return obj;
    },
		dateIsToday() {
			if (!this.date) return false;

			const today = new Date().toISOString().substr(0, 10);
			return this.date === today;
		}
  },
  methods: {
    disablePastDates(val) {
      if (this.allowPastDates) {
        return true;
      }
      const today = new Date().toISOString().substr(0, 10);
      return val >= today;
    },
		titleDate(date) {
			if (!date) return null;

			const [year, month, day] = date.split('-');
			const today = new Date().toISOString().substr(0, 10);
			const [todayYear, todayMonth, todayDay] = today.split('-');

			if (year === todayYear && month === todayMonth && day === todayDay) {
				return `${day}-${month}-${year}`;
			} else {
				return `До ${day}-${month}-${year}`;
			}
		},
    handleInput(e) {
      this.$emit("update:date", e);
      this.dateSelected = true;
    },
    selectToday() {
      const today = new Date().toISOString().substr(0, 10);
      this.$emit("update:date", today);
      this.dateSelected = true;
    },
  },
};
</script>

<style>
/* .date-picker .v-tabs-bar .v-slide-group__content .v-tabs-slider-wrapper {
	width: 180px !important;
} */

.text-simple {
  color: rgba(0, 0, 0, 0.87) !important;
  letter-spacing: 0.009375em !important;
  line-height: 1.75rem !important;
  font-family: "Roboto", sans-serif !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
}
.v-input--selection-controls__input .v-input .v-label {
  color: rgba(0, 0, 0, 0.87) !important;
  letter-spacing: 0.009375em !important;
  line-height: 1.75rem !important;
  font-family: "Roboto", sans-serif !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
}
</style>

