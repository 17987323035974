<template>
	<v-tooltip top color="primary">
		<template v-slot:activator="{ on, attrs }">
			<div
				class="houses-item-board-apartments__item"
				:class="{ 'primary white--text': isActive }"
				v-bind="attrs"
				v-on="on"
			>
				{{ item.apartment_number }}
			</div>
		</template>
		<h6 class="subtitle-1 mb-1">{{ `Квартира №${item.apartment_number}` }}</h6>
		<TextBox class="text-subtitle-2">Собственник: {{ cOwner }}</TextBox>
	</v-tooltip>
</template>

<script>
export default {
	name: "HousesItemBoardApartmentsItem",

	props: {
		item: {
			type: Object,
			required: true,
		},
	},

	data() {
		return {
			owner: null,
		};
	},

	computed: {
		isActive() {
			return this.cSearchResult?._id === this.item._id;
		},
		cOwner() {
			return this.owner?.data.user?.phone || "не указан";
		},
		cSearchResult() {
			return this.$store.getters["board/GET_SEARCH_RESULT"];
		},
	},

	async beforeMount() {
		this.owner = await this.$api.ksk.load_apartment_owner(this.item._id);
	},
};
</script>

<style lang="scss" scoped>
$size: 50px;
$grey: #f5f5f5;
$grey-darken: #bdbdbd;

.houses-item-board-apartments__item {
	display: flex;
	justify-content: center;
	margin-right: 30px;
	align-items: center;
	height: $size;
	width: $size;
	min-width: $size;
	background: $grey;
	cursor: pointer;
	transition: all 0.3s ease;
	&:hover {
		background: $grey-darken;
	}
}
</style>
